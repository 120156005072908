<!-- 练习成果 -->
<template>
  <div class="box">
    <van-nav-bar
      title="练习成果"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="achievement" v-if="practiceResult.length > 0">
      <div class="list">
        <div
          class="achievementitem"
          v-for="(item, index) in practiceResult"
          :key="index"
        >
          <div class="ctx-l">
            <div>{{ item.task_stage }}阶段</div>
            <div v-if="item.is_medal == 0" class="wuxunzhang">暂未获得勋章</div>
            <div v-if="item.is_medal == 1">
              <img
                v-if="item.task_stage == 1"
                src="https://lesson.iapeap.com/images/EmotionalValue/1jie.png"
                alt=""
              />
              <img
                v-if="item.task_stage == 2"
                src="https://lesson.iapeap.com/images/EmotionalValue/2jie.png"
                alt=""
              />
              <img
                v-if="item.task_stage == 3"
                src="https://lesson.iapeap.com/images/EmotionalValue/3jie.png"
                alt=""
              />
              <img
                v-if="item.task_stage == 4"
                src="https://lesson.iapeap.com/images/EmotionalValue/4jie.png"
                alt=""
              />
            </div>
          </div>
          <div @click="gomedal(item)">查看报告</div>
        </div>
      </div>
    </div>
    <div class="nullData" v-if="practiceResult.length == 0">
      <div class="nullDatactx">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/kongbox.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { practiceResult, memberLog } from "../../api/login";

export default {
  data() {
    return {
      name: "",
      practiceResult: [],
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  created() {
    this.getpracticeResult();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getpracticeResult() {
      let data = { userId: window.localStorage.getItem("userId") };
      practiceResult(data).then((res) => {
        this.practiceResult = res.data;
        console.log("长度：", this.practiceResult.length, this.practiceResult);
      });
    },
    gomedal(item) {
      this.$router.push({
        path: "/medals/medal",
        query: { item: item },
      });
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "练习成果",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
// 空数据样式
.nullData {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .nullDatactx {
    color: #464646;
    img {
      width: 200px;
    }
    text-align: center;
  }
}

.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.box {
  padding-bottom: 40px;
}
.achievement {
  .list {
    padding: 0 40px;
    box-sizing: border-box;
    .achievementitem {
      margin-top: 20px;
      height: 48px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      color: #5faefa;
      font-size: 16px;
      .ctx-l {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        .ctx-l-g {
          height: 14px;
          font-size: 10px;
          font-weight: 400;
          color: #a6bad4;
          line-height: 14px;
        }
        img {
          width: 21px;
          height: 21px;
        }
      }
    }
  }
  .wuxunzhang {
    font-size: 10px;
    font-weight: 400;
    color: #a6bad4;
  }
}
</style>
